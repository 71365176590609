import { Question } from '../../shared/types/product-selector/types';
import { ResponseContainerProps } from '../components/response-container/response-container';

export const findQuestionToShow = (
  nextQuestion: number,
  questions: Question[],
  selections: ResponseContainerProps['selectedResponses'],
  direction: 'prev' | 'next',
): number => {
  if (direction === 'prev' && nextQuestion <= 0) return 0;
  if (direction === 'next' && nextQuestion >= questions.length) return questions.length;
  const nextQuestionSuppress = questions[nextQuestion].suppress;
  if (nextQuestionSuppress) {
    const needle = nextQuestionSuppress.find((suppress) => {
      const questionName = questions[suppress.question - 1].name;
      return selections?.[questionName]?.[suppress.comparison];
    });
    if (needle) {
      return findQuestionToShow(
        direction === 'next' ? nextQuestion + 1 : nextQuestion - 1,
        questions,
        selections,
        direction,
      );
    }
  }
  return nextQuestion;
};
