import React, { FC } from 'react';
import './recommendation-card.scss';
import { Recommendation } from '../../../shared/types/product-selector/types';
import { RiPaintBrushLine } from 'react-icons/ri';

export interface RecommendationCardProps {
  downloadChecklist: string;
  viewDetails: string;
  recommendation: Recommendation;
}

const RecommendationCard: FC<RecommendationCardProps> = ({ downloadChecklist, viewDetails, recommendation }) => {
  const { productImage, name, superTitle, subTitle, description, callToActionType, callToActionUrl } = recommendation;
  return (
    <div className='ps-recommendation-card'>
      <img className='ps-recommendation-card__product-image' src={productImage} alt={description ?? ''} />
      <h4 className='ps-recommendation-card__name'>
        {superTitle && <small className='ps-recommendation-card__super-title'>{superTitle}</small>}
        <span>{name}</span>
        {subTitle && <small className='ps-recommendation-card__sub-title'>{subTitle}</small>}
      </h4>

      {description && <p className='ps-recommendation-card__description'>{description}</p>}
      {callToActionType === 'btn' && (
        <a href={callToActionUrl} className='ps-button ps-button--primary' target='_blank' rel='noreferrer'>
          {downloadChecklist}
        </a>
      )}
      {callToActionType === 'link' && (
        <a href={callToActionUrl} className='ps-button ps-button--link' target='_blank' rel='noreferrer'>
          <RiPaintBrushLine /> <span>{viewDetails}</span>
        </a>
      )}
    </div>
  );
};

export default RecommendationCard;
