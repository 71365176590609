/* eslint @typescript-eslint/no-throw-literal: 0 */
import axios from 'axios';
import { SetStateAction, useEffect, useState } from 'react';

export interface GetDataResource<T> {
  read: () => T | null;
}

const promiseWrapper = <T>(promise: Promise<T | null>, transform?: (data: T) => T | null) => {
  let status = 'pending';
  let result: unknown;

  const suspender = promise.then(
    (value) => {
      status = 'success';
      if (transform && value) {
        result = transform(value);
      } else {
        result = value;
      }
    },
    (error) => {
      status = 'error';
      result = error;
    }
  );

  return {
    read() {
      switch (status) {
        case 'pending':
          throw suspender;
        case 'success':
          return result;
        case 'error':
          throw result;
        default:
          throw new Error('Unknown status');
      }
    },
  };
};

function useGetData<T>(url: string, transform?: (data: T) => T | null): [GetDataResource<T> | null, AbortController] {
  const [resource, setResource] = useState<GetDataResource<T> | null>(null);
  const abortController = new AbortController();

  useEffect(() => {
    if (url) {
      const promise = axios.get<T>(url, { signal: abortController.signal }).then((response) => response.data);
      setResource(promiseWrapper(promise, transform) as SetStateAction<GetDataResource<T> | null>);
      return () => abortController.abort();
    } else {
      console.warn(`URL not properly set`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return [resource, abortController];
}

export default useGetData;
