import React, { StrictMode } from 'react';
import './index.scss';
import ProductSelectorAuthoringGet from './product-selector';
import { createRoot } from 'react-dom/client';

const container: HTMLElement | null = document.querySelector('.cmp-product-selector-root');

if (container) {
  const slingModelAPI = container.dataset.resourcePath;
  const root = createRoot(container);
  root.render(
    <StrictMode>
      <ProductSelectorAuthoringGet slingModelAPI={slingModelAPI ?? ''} />
    </StrictMode>,
  );
}
