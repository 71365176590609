import React, { FC, Suspense, useCallback, useEffect, useState } from 'react';
import './product-selector.scss';
import Spinner from '../../shared/componets/spinner';
import useGetData, { type GetDataResource } from '../../shared/hooks/use-get-data';
import {
  ProductSelectorAuthoredData,
  Question,
  QuestionsWrapper,
  Recommendation,
  RecommendationsWrapper,
} from '../../shared/types/product-selector/types';
import ProgressBar from './progress-bar/progress-bar';
import Title from './title/title';
import ResponseContainer, { ResponseContainerProps } from './response-container/response-container';
import ButtonContainer from './button-container/button-container';
import { findQuestionToShow } from '../util/findQuestionToShow';
import RecommendationContainer from './recommendation-container/recommendation-container';
import Summary from './summary/summary';
import { pushProjectHelperAnalytics } from '../../shared/utils/GA4DataLayerHelper';

interface ProductSelectorContainerProps {
  slingModelAPI: string;
}

interface ProductSelectorDataGetProps {
  authoredData: ProductSelectorAuthoredData;
}

interface ProductSelectorDataCheckProps {
  authorResource: [GetDataResource<ProductSelectorAuthoredData> | null, AbortController];
}

interface ProductSelectorProps {
  authoredData: ProductSelectorAuthoredData;
  questionsResource: [GetDataResource<QuestionsWrapper> | null, AbortController];
  recommendationsResource: [GetDataResource<RecommendationsWrapper> | null, AbortController];
}

const ProductSelector: FC<ProductSelectorProps> = ({ authoredData, questionsResource, recommendationsResource }) => {
  const questionsData = questionsResource[0]?.read();
  const recommendationsData = recommendationsResource[0]?.read();
  const [questions, setQuestions] = useState<Question[]>([]);
  const [recommendations, setRecommendations] = useState<Recommendation[]>([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [selectedResponses, setSelectedResponses] = useState<ResponseContainerProps['selectedResponses']>({});

  const nextFn = useCallback(() => {
    const nextQuestion = findQuestionToShow(currentQuestion + 1, questions, selectedResponses, 'next');

    setCurrentQuestion(Math.min(nextQuestion, questions.length));
    pushProjectHelperAnalytics("quiz_next", currentQuestion, questions, selectedResponses)
  }, [currentQuestion, questions, selectedResponses]);

  const prevFn = useCallback(() => {
    const nextQuestion = findQuestionToShow(currentQuestion - 1, questions, selectedResponses, 'prev');
    setCurrentQuestion(Math.max(nextQuestion, 0));
    pushProjectHelperAnalytics("quiz_previous", currentQuestion, questions, selectedResponses);
  }, [currentQuestion, questions, selectedResponses]);

  const startOverFn = () => {
    setCurrentQuestion(0);
    setSelectedResponses({});
    pushProjectHelperAnalytics("quiz_restart", currentQuestion, questions, selectedResponses);
  };

  const summaryCallback = (index: number) => {
    setCurrentQuestion(index);
  };

  // This use effect strips off some extra layers in the JSON
  useEffect(() => {
    if (questionsData) {
      setQuestions(questionsData?.questionnaires[0].questions);
    }
    if (recommendationsData) {
      setRecommendations(recommendationsData.recommendations);
    }
  }, [questionsData, recommendationsData]);

  useEffect(() => {
    if (Object.values(selectedResponses?.[questions[currentQuestion]?.name] ?? {}).some((v) => v)) {
      setNextDisabled(false);
    } else {
      setNextDisabled(true);
    }
  }, [currentQuestion, questions, selectedResponses]);

  const filteredQuestions = questions.filter(
      (question) => Object.values(selectedResponses?.[question.name] ?? {}).some(v => v)
  );

  return (
    <div className='ps'>
      <ProgressBar segments={questions.length} current={currentQuestion} />
      {questions.length > currentQuestion && (
        <>
          <Title
            title={questions[currentQuestion].questionText}
            isCombo={questions[currentQuestion].inputType === 'combo'}
            instructions={authoredData.instructions}
          />
          <ResponseContainer
            {...{
              none: authoredData.none ?? 'None is not authored',
              questions,
              currentQuestion,
              selectedResponses,
              setSelectedResponses,
            }}
          />
        </>
      )}
      {questions.length === currentQuestion && (
        <>
          <Summary
            {...{
              authoredData,
              questions: filteredQuestions,
              selectedResponses,
              callback: summaryCallback,
            }}
          />
          <RecommendationContainer
            {...{
              authoredData,
              recommendations,
              selectedResponses,
            }}
          />
        </>
      )}
      <ButtonContainer
        {...{
          authoredData,
          currentQuestion,
          maxQuestions: questions.length,
          nextDisabled,
          nextFn,
          prevFn,
          startOverFn,
        }}
      />
    </div>
  );
};

const ProductSelectorDataGet: FC<ProductSelectorDataGetProps> = ({ authoredData }) => {
  const questionsResource = useGetData<QuestionsWrapper>(authoredData.questionsURL ?? '');
  const recommendationsResource = useGetData<RecommendationsWrapper>(authoredData.recommendationsURL ?? '');

  if (!questionsResource[0]) {
    return <h2>questionsResource not ready</h2>;
  }

  if (!recommendationsResource[0]) {
    return <h2>recommendationsResource not ready</h2>;
  }

  return (
    <Suspense fallback={<Spinner full />}>
      <ProductSelector
        authoredData={authoredData}
        questionsResource={questionsResource}
        recommendationsResource={recommendationsResource}
      />
    </Suspense>
  );
};

const ProductSelectorDataCheck: FC<ProductSelectorDataCheckProps> = ({ authorResource }) => {
  const authoredData = authorResource[0]?.read();

  if (!authoredData?.questionsURL) {
    return <h2>QuestionsURI not authored</h2>;
  }

  if (!authoredData?.recommendationsURL) {
    return <h2>recommendationsURI not authored</h2>;
  }

  return <ProductSelectorDataGet authoredData={authoredData} />;
};

const ProductSelectorAuthoringGet: FC<ProductSelectorContainerProps> = ({ slingModelAPI }) => {
  const authorResource = useGetData<ProductSelectorAuthoredData>(`${slingModelAPI}.model.json`);

  return (
    <Suspense fallback={<Spinner full />}>
      <ProductSelectorDataCheck authorResource={authorResource} />
    </Suspense>
  );
};

export default ProductSelectorAuthoringGet;
