import React, { ReactElement } from 'react'
import { MoonLoader } from 'react-spinners'
import './spinner.scss'

const Spinner = ({ full }: { full?: boolean }): ReactElement => (
  <div className={`spinner${full ? ' spinner--full' : ''}`}>
    <MoonLoader color='#474747' size={120} speedMultiplier={0.75} />
  </div>
)

export default Spinner
