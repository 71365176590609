import React, { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react';
import './response-container.scss';
import ResponseCard from '../response-card/response-card';
import { Question } from '../../../shared/types/product-selector/types';
import { findResponseToShow } from '../../util/findResponseToShow';
import { pushProjectHelperInteraction } from '../../../shared/utils/GA4DataLayerHelper';

export interface ResponseContainerProps {
  none: string;
  questions: Question[];
  currentQuestion: number;
  selectedResponses?: Record<string, Record<string, boolean>>;
  setSelectedResponses?: Dispatch<SetStateAction<ResponseContainerProps['selectedResponses']>>;
}

const ResponseContainer: FC<ResponseContainerProps> = ({
  none,
  questions,
  currentQuestion,
  selectedResponses,
  setSelectedResponses,
}) => {
  const { name: questionName, inputType, displayType } = questions[currentQuestion];

  const setSelectedResponseWrapper = useCallback(
    (value: string) => {
      pushProjectHelperInteraction(currentQuestion, questions, value);
      if (setSelectedResponses) {
        switch (true) {
          case inputType === 'radio' || value === 'none' || selectedResponses?.[questionName]?.none:
            setSelectedResponses({
              ...selectedResponses,
              [questionName]: {
                [value]: !selectedResponses?.[questionName]?.[value],
              },
            });
            break;
          case inputType === 'combo':
            setSelectedResponses({
              ...selectedResponses,
              [questionName]: {
                ...selectedResponses?.[questionName],
                [value]: !selectedResponses?.[questionName]?.[value],
              },
            });
            break;
          default:
            console.warn(`Unknown inputType: ${inputType}`);
        }
      }
    },
    [currentQuestion, inputType, questionName, questions, selectedResponses, setSelectedResponses],
  );

  const responses = useMemo(() => {
    return findResponseToShow(questions, currentQuestion, selectedResponses);
  }, [currentQuestion, questions, selectedResponses]);

  return (
    <div className='ps-response-container'>
      {responses?.map((response, index) => (
        <ResponseCard
          displayType={displayType}
          response={response}
          isSelected={selectedResponses?.[questionName]?.[response.value]}
          callback={setSelectedResponseWrapper}
          key={index}
        />
      ))}
      {displayType.includes('includeNone') && (
        <ResponseCard
          displayType={['includeNone']}
          response={{ title: none, value: 'none' }}
          isSelected={selectedResponses?.[questionName]?.none}
          callback={setSelectedResponseWrapper}
        />
      )}
    </div>
  );
};

export default ResponseContainer;
