import React, { FC, KeyboardEvent, useRef } from 'react';
import './response-card.scss';
import { Question, Response } from '../../../shared/types/product-selector/types';
import { Tooltip } from 'react-tooltip';
import { FaCircleInfo } from 'react-icons/fa6';

export interface ResponseCardProps {
  displayType: Question['displayType'];
  response: Response;
  callback?: (value: string) => void;
  isSelected?: boolean;
}

const ResponseCard: FC<ResponseCardProps> = ({ displayType, response, callback, isSelected }) => {
  const cardRefs = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    if (callback) callback(response.value);
  };
  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    switch (e.code) {
      case 'Enter':
        e.stopPropagation();
        e.preventDefault();
        if (callback) callback(response.value);
    }
  };

  return (
    <div
      ref={cardRefs}
      className={`ps-response-card${isSelected ? ' ps-response-card--selected' : ''}`}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      role='button'
    >
      {displayType.includes('title') && <div className='ps-response-card__title'>{response.title}</div>}
      {displayType.includes('image') && response.imageURL !== undefined && (
        <picture>
          <source srcSet={response.imageURL} media='(min-width: 800px)' />
          <img
            className='ps-response-card__image'
            src={response.mobileImageURL ?? response.imageURL}
            alt={response.title}
          />
        </picture>
      )}
      {displayType.includes('includeNone') && displayType.length === 1 && (
        <div className='ps-response-card__none'>{response.title}</div>
      )}
      {(displayType.includes('footerTitle') ||
        (displayType.includes('infoText') && response.infoText !== undefined) ||
        displayType.includes('infoTextAsTooltip')) && (
        <div className='ps-response-card__footer'>
          {displayType.includes('footerTitle') && (
            <div className='ps-response-card__footer-title'>{response.title}</div>
          )}
          {displayType.includes('infoText') && (
            <div className='ps-response-card__info-text'>{response.infoText ?? 'no info text found'}</div>
          )}
          {displayType.includes('infoTextAsTooltip') && (
            <>
              <div
                className={`ps-response-card__info-text-tooltip ps-response-card__info-text-tooltip--${response.value}`}
                tabIndex={0}
              >
                <FaCircleInfo />
              </div>
              <Tooltip
                className='ps-response-card__tooltip'
                anchorSelect={`.ps-response-card__info-text-tooltip--${response.value}`}
              >
                {response.infoText}
              </Tooltip>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ResponseCard;
