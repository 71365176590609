import React, { FC, useEffect, useState } from 'react';
import './recommendation-counter.scss';

export interface RecommendationCounterProps {
  recommendationText: string;
  recommendationCount: number;
}

const RecommendationCounter: FC<RecommendationCounterProps> = ({ recommendationText, recommendationCount }) => {
  const [text, setText] = useState('');

  useEffect(() => {
    if (recommendationText.includes('%recommendationCount%')) {
      setText(recommendationText.replace('%recommendationCount%', recommendationCount.toString()));
    } else {
      setText(`${recommendationText} ${recommendationCount}`);
    }
  }, [recommendationCount, recommendationText]);

  return <h3 className='ps-results-counter'>{text}</h3>;
};

export default RecommendationCounter;
