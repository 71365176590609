import React, { FC } from 'react';
import './button-container.scss';
import Button from '../button/button';
import { ProductSelectorAuthoredData } from '../../../shared/types/product-selector/types';

export interface ButtonContainerProps {
  authoredData: ProductSelectorAuthoredData;
  currentQuestion: number;
  maxQuestions: number;
  nextDisabled: boolean;
  nextFn: () => void;
  prevFn: () => void;
  startOverFn: () => void;
}

const ButtonContainer: FC<ButtonContainerProps> = ({
  authoredData,
  currentQuestion,
  maxQuestions,
  nextDisabled,
  nextFn,
  prevFn,
  startOverFn,
}) => (
  <div className='ps-button-container'>
    <div className='ps-button-container__row'>
      {currentQuestion > 0 && <Button text={authoredData.previous ?? 'Previous not authored'} callback={prevFn} />}
      {currentQuestion < maxQuestions && (
        <Button text={authoredData.next ?? 'Next not authored'} isDisabled={nextDisabled} callback={nextFn} />
      )}
      {currentQuestion >= maxQuestions && (
        <Button text={authoredData.startOver ?? 'Start Over not authored'} callback={startOverFn} shouldFocus />
      )}
    </div>
    {currentQuestion > 0 && currentQuestion < maxQuestions && (
      <div className='ps-button-container__row'>
        <Button text={authoredData.startOver ?? 'Start Over not authored'} callback={startOverFn} style='link' />
      </div>
    )}
  </div>
);

export default ButtonContainer;
