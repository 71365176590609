import React, { FC } from 'react';
import './progress-bar.scss';

export interface ProgressBarProps {
  segments: number;
  current: number;
}

const ProgressBar: FC<ProgressBarProps> = ({ segments, current }) => {
  return (
    <div className='ps-progress-bar'>
      {[...Array(segments)].map((_, index) => (
        <div
          key={index}
          className={`ps-progress-bar__segment${index === current ? ' ps-progress-bar__segment--current' : ''}`}
        />
      ))}
      <div className={`ps-progress-bar__segment${current >= segments ? ' ps-progress-bar__segment--current' : ''}`} />
    </div>
  );
};

export default ProgressBar;
