import { ResponseContainerProps } from '../components/response-container/response-container';
import { Recommendation } from '../../shared/types/product-selector/types';

export const findRecommendationsToShow = (
  recommendations: Recommendation[],
  selections: ResponseContainerProps['selectedResponses'],
) => {
  const toReturn = recommendations.reduce<Set<string>>((accumulator, current) => {
    if (selections) {
      current.match?.forEach((match) => {
        const valuesMatch = Object.keys(match).reduce<Set<string>>((accumulator, questionKey) => {
          // this line is necessary so typescript can properly do type narrowing
          const matchQuestionValue = match[questionKey];
          if (Array.isArray(matchQuestionValue)) {
            matchQuestionValue.forEach((responseKey) => {
              if (selections[questionKey]?.[responseKey]) accumulator.add(questionKey);
            });
          } else {
            if (matchQuestionValue === '' || selections?.[questionKey]?.[matchQuestionValue])
              accumulator.add(questionKey);
          }
          return accumulator;
        }, new Set());
        if (valuesMatch.size === Object.keys(match).length) accumulator.add(current.code);
      });
    }
    return accumulator;
  }, new Set());
  return recommendations.filter((recommendation) => toReturn.has(recommendation.code));
};
