import React, { FC } from 'react';
import './title.scss';

export interface TitleProps {
  title: string;
  isCombo: boolean;
  instructions?: string;
}

const Title: FC<TitleProps> = ({ title, isCombo, instructions = '(Select all that apply)' }) => (
  <>
    <h3 className={`ps-title${isCombo ? ' ps-title--combo' : ''}`}>{title}</h3>
    {isCombo && <h4 className='ps-instructions'>{instructions}</h4>}
  </>
);

export default Title;
