import React, { FC, KeyboardEvent, useEffect, useRef } from 'react';
import './button.scss';

export interface ButtonProps {
  text: string;
  isDisabled?: boolean;
  callback?: () => void;
  style?: 'primary' | 'link';
  shouldFocus?: boolean;
}

const Button: FC<ButtonProps> = ({ text, isDisabled, callback, style = 'primary', shouldFocus }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleClick = () => {
    if (callback) callback();
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLButtonElement>) => {
    switch (e.code) {
      case 'Enter':
        e.stopPropagation();
        e.preventDefault();
        if (callback && !isDisabled) callback();
    }
  };

  useEffect(() => {
    if (shouldFocus) {
      buttonRef.current?.focus();
    }
  }, [shouldFocus]);

  return (
    <button
      ref={buttonRef}
      className={`ps-button ps-button--${style}${isDisabled ? ' ps-button--disabled' : ''}`}
      aria-disabled={isDisabled}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
    >
      {text}
    </button>
  );
};

export default Button;
