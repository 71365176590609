import React, { CSSProperties, FC, KeyboardEvent, MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { ProductSelectorAuthoredData, Question } from '../../../shared/types/product-selector/types';
import { ResponseContainerProps } from '../response-container/response-container';
import './summary.scss';

export interface SummaryProps {
  authoredData: ProductSelectorAuthoredData;
  questions: Question[];
  selectedResponses: ResponseContainerProps['selectedResponses'];
  callback?: (index: number) => void;
}

interface ResponseTextProps {
  noneSummaryText: string;
  question: Question;
  selectedResponses: ResponseContainerProps['selectedResponses'];
}

const ResponseText: FC<ResponseTextProps> = ({ noneSummaryText, question, selectedResponses }) => {
  const entries = Object.entries(selectedResponses?.[question.name] ?? {}).filter(([_, value]) => value);
  if (entries.length > 0) {
    return (
      <>
        {entries.map(([key], index) => (
          <span key={index}>
            {question.responses.find((response) => response.value === key)?.title ?? noneSummaryText}
          </span>
        ))}
      </>
    );
  }
  return null;
};

const Summary: FC<SummaryProps> = ({ authoredData, questions, selectedResponses, callback }) => {
  const [columns, setColumns] = useState(2);
  const [rows, setRows] = useState(1);
  const summaryRef = useRef<HTMLDivElement>(null);
  const { summary = 'Summary is not authored' } = authoredData;

  const handleClick = (e: MouseEvent<HTMLAnchorElement>, index: number) => {
    e.stopPropagation();
    e.preventDefault();

    let indexToUse = Number(index);
    while (
      Object.values(selectedResponses?.[questions[indexToUse].name] ?? {}).filter((value) => value).length === 0 &&
      indexToUse > 0
    ) {
      --indexToUse;
    }
    if (callback) {
      callback(indexToUse);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLAnchorElement>, index: number) => {
    switch (e.code) {
      case 'Enter': {
        e.stopPropagation();
        e.preventDefault();
        let indexToUse = Number(index);
        while (
          Object.values(selectedResponses?.[questions[indexToUse].name] ?? {}).filter((value) => value).length === 0 &&
          indexToUse > 0
        ) {
          --indexToUse;
        }
        if (callback) callback(index);
      }
    }
  };

  const calcGridSize = useCallback(() => {
    const offsetWith = summaryRef.current?.offsetWidth ?? 0;
    const columnsToSet = Math.floor(offsetWith / 182); // width + gap
    const rowsToSet = Math.ceil(questions.length / (columnsToSet - 1));
    setColumns(columnsToSet);
    setRows(rowsToSet);
  }, [questions.length]);

  useEffect(() => {
    window.addEventListener('resize', calcGridSize);
    calcGridSize();

    return () => window.removeEventListener('resize', calcGridSize);
  }, [calcGridSize]);

  return (
    <div
      ref={summaryRef}
      className='ps-summary'
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      style={{ '--ps-summary-column-number': columns, '--ps-summary-row-number': rows } as CSSProperties}
    >
      <div className='ps-summary__summary'>
        <h3>{summary}</h3>
      </div>
      <div className='ps-summary__item-wrapper'>
        {questions.map((question, index) => (
          // make it 1 indexed and the subgrid has one less column
          <div
            className={`ps-summary__item${(index + 1) % (columns - 1) > 0 ? ' ps-summary__item--border' : ''}`}
            key={index}
          >
            <a
              href='#'
              className='ps-summary__item-title results-summary'
              onClick={(e) => handleClick(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
            >
              <h4>{question.summaryText}</h4>
            </a>
            <p>
              <ResponseText
                {...{
                  noneSummaryText: authoredData.noneSummaryText ?? 'None Summary Text Not Authored',
                  question,
                  selectedResponses,
                }}
              />
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Summary;
