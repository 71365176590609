import React, { FC, useMemo } from 'react';
import './recommendation-container.scss';
import RecommendationCard from '../recommendation-card/recommendation-card';
import RecommendationCounter from '../recommendation-counter/recommendation-counter';
import { ProductSelectorAuthoredData, Recommendation } from '../../../shared/types/product-selector/types';
import { findRecommendationsToShow } from '../../util/findRecommendationsToShow';
import { pushProjectHelperResults } from '../../../shared/utils/GA4DataLayerHelper';

export interface RecommendationContainerProps {
  authoredData: ProductSelectorAuthoredData;
  recommendations: Recommendation[];
  selectedResponses?: Record<string, Record<string, boolean>>;
}

const RecommendationContainer: FC<RecommendationContainerProps> = ({
  authoredData,
  recommendations,
  selectedResponses,
}) => {
  const {
    recommendationsCount = 'recommendationsCount is not authored',
    downloadChecklist = 'downloadChecklist is not authored',
    viewDetails = 'viewDetails is not authored',
  } = authoredData;
  const recommendationsToShow = useMemo(() => {
    const recommendationsData = findRecommendationsToShow(recommendations, selectedResponses);
    pushProjectHelperResults(recommendationsData);
    return recommendationsData;
  }, [recommendations, selectedResponses]);
  return (
    <>
      <RecommendationCounter
        recommendationText={recommendationsCount ?? 'recommendationsCount is not authored'}
        recommendationCount={recommendationsToShow.length}
      />
      <div className='ps-recommendation-container'>
        {recommendationsToShow.map((recommendation, index) => (
          <RecommendationCard {...{ downloadChecklist, viewDetails, recommendation }} key={index} />
        ))}
      </div>
    </>
  );
};

export default RecommendationContainer;
