import { Question, Response } from '../../shared/types/product-selector/types';
import { ResponseContainerProps } from '../components/response-container/response-container';

export const findResponseToShow = (
  questions: Question[],
  currentQuestion: number,
  selectedResponses: ResponseContainerProps['selectedResponses'],
) => {
  if (currentQuestion >= questions.length) return [];
  const nextQuestionResponses = questions[currentQuestion].responses;
  return nextQuestionResponses.reduce<Response[]>((accumulator, current) => {
    const matches = current.suppress?.map<boolean>((suppress) => {
      const questionName = questions[suppress.question - 1].name;
      return selectedResponses?.[questionName][suppress.comparison] ?? false;
    });
    if (matches?.some((m) => m)) {
      return accumulator;
    }
    return [...accumulator, current];
  }, []);
};
